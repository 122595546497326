<template>
  <div id="app">
    <div class="row">
      <div class="col-md-12 text-start">
        <h5><strong>Bandeja de Solicitud</strong></h5>
        <hr />
      </div>
    </div>
    <data-table v-bind="parametersTable1" @actionTriggered="handleAction" />
    <!-- <data-table v-bind="parametersTable1" /> -->
    <b-modal
      hide-footer
      v-model="modalShow"
      title="Aprobar / Rechazar Solicitud"
    >
      <div class="row">
        <div class="col-md-12">
          <h5 class="text-start mt-1 mb-2">Datos de la Empresa</h5>
          <hr />
          <div class="row mb-3">
            <div class="col-md-4 form-group">
              <label for="rnc">RNC</label>
              <input
                type="text"
                id="rnc"
                class="form-control"
                v-model="item.rnc"
                readonly
              />
              <span class="error d-none"></span>
            </div>
            <div class="col-md-8 form-group">
              <label for="business_name">Nombre</label>
              <input
                type="text"
                id="business_name"
                class="form-control"
                v-model="item.businessName"
                readonly
              />
              <span class="error d-none"></span>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <b-form-group>
            <b-form-radio-group
              v-model="item.status"
              :options="options"
              class="mb-3"
              value-field="item"
              text-field="name"
              size="lg"
              style="display: flex"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-12 my-4 text-end form-group">
        <b-button @click="modalShow = false" class="mx-4 btn-danger btn"
          ><i class="fa fa-times"> </i> &nbsp; Cancelar</b-button
        >
        <b-button variant="primary" @click="updateStatus"
          ><i class="fa fa-save"> </i> &nbsp;Guardar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<style lang="css">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
main {
  padding: 32px;
}
</style>

<script>
import {
  getPlatformRequests,
  updateEstatusPlatformRequest,
} from "@/services/PlatformRequest";
import Swal from "sweetalert2";

import moment from "moment";
export default {
  components: {},
  data() {
    return {
      modalShow: false,
      dataList: [],
      item: {
        id: 0,
        rnc: "",
        businessName: "",
        createdAt: "",
        email: "",
        statusName: "",
        status: 0,
      },
      selected: 0,
      options: [
        { item: 0, name: "Pendiente" },
        { item: 1, name: "Aprobado" },
        { item: 2, name: "Rechazado" },
      ],
    };
  },
  mounted() {
    this.getPlatformRequest();
  },
  props: {},
  methods: {
    async updateStatus() {
      try {
        let payload = {
          status: this.item.status,
          // rnc: this.item.rnc,
          // business_name: this.item.businessName,
          email: this.item.email,
        };

        await updateEstatusPlatformRequest(this.item.id, payload)
          .then((res) => {
            if (res.success) {
              this.getPlatformRequest();
              this.modalShow = false;
              Swal.fire({
                title: "¡Mensaje!",
                text: res.message,
                icon: "primary",
                confirmButtonText: "Volver",
              });
            }
          })
          .catch(() => {
            Swal.fire({
              title: "¡Ha ocurrido un error!",
              text: "Favor intente de nuevo o contacte un técnico.",
              icon: "error",
              confirmButtonText: "Volver",
            });
          });
      } catch (error) {
        Swal.fire({
          title: "¡Ha ocurrido un error!",
          text: "Favor intente de nuevo o contacte un técnico.",
          icon: "error",
          confirmButtonText: "Volver",
        });
      }
    },
    async getPlatformRequest() {
      try {
        const { data } = await getPlatformRequests();
        if (data) {
          this.dataList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleAction(actionName, data) {
      this.item.id = data.id;
      this.item.rnc = data.rnc;
      this.item.businessName = data.business_name;
      this.item.email = data.email;
      this.item.status = data.status;

      if (this.item.status == 1) {
        Swal.fire({
          title: "¡Mensaje!",
          text: "Esta solicitud ya fue aprobada.",
          icon: "warning",
          confirmButtonText: "Volver",
        });
      } else {
        this.modalShow = true;
      }
    },
  },
  computed: {
    parametersTable1() {
      return {
        data: this.dataList.map((x) => {
          var status = "";
          if (x.status == 0) {
            status = "Pendiente";
          }
          if (x.status == 1) {
            status = "Aprobado";
          }
          if (x.status == 2) {
            status = "Rechazado";
          }
          return {
            ...x,
            statusName: status,
            dateFormatted: moment(x.created_at).format("DD-MM-YYYY, h:mm:ss a"),
          };
        }),
        lang: "es",
        actionMode: "single",
        actions: ["view"],
        columns: [
          {
            key: "id",
            title: "Id",
          },
          {
            key: "rnc",
            title: "Rnc",
          },
          {
            key: "email",
            title: "Email",
          },
          {
            key: "business_name",
            title: "Nombre de la Empresa",
          },
          {
            key: "statusName",
            title: "Estatus",
          },
          {
            key: "dateFormatted",
            title: "Creado en Fecha",
          },
        ],
      };
    },
  },
};
</script>
